<template>
    <div class="container border-container EvaluationList">
        <div class="topBtns">
            <el-button v-if="canEdit" type="primary" icon="el-icon-plus" @click="routerChange(1)">新增模板</el-button>
            <el-button class="topBtns-btn" v-if="canEdit" type="primary" @click="routerChange(4)">
                <i class="iconfont icon-copy" style="font-size: 13px"/>
                复制模板
            </el-button>
        </div>
        <div class="search-container">
            <span class="list-title">月评模板</span>
        </div>
        <div class="table-container">
            <el-table ref="multipleTable" :data="evaluationList" tooltip-effect="dark" style="width: 100%" size="medium"
                @selection-change="handleSelectionChange"
                :header-cell-style="{height: '40px', background: 'linear-gradient(180deg,rgba(251,252,253,1) 0%,rgba(246,248,250,1) 100%)'}"
                :cell-style="{height: '50px',padding: '5px 0'}">
                <el-table-column type="selection" width="50" align="center"></el-table-column>
                <el-table-column prop="month" label="月" min-width="65">
                    <template slot-scope="scope">
                        <div class="month-tag">{{ scope.row.month | monthFilter }}月</div>
                    </template>
                </el-table-column>
                <el-table-column label="时间段" min-width="250">
                    <template slot-scope="scope">
                        <div class="fz-bold hover-text-colourful" @click="routerChange(3, scope.row)">
                            {{scope.row.begin_time | timeFilter(2)}} - {{scope.row.end_time | timeFilter(2)}}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="模板名称" min-width="120" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        <div class="fz-bold hover-text-colourful" @click="routerChange(3, scope.row)">{{scope.row.topic.name}}</div>
                    </template>
                </el-table-column>
                <el-table-column label="设计人" min-width="80" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        {{designer ? designer : (scope.row.creator.name || '-')}}
                    </template>
                </el-table-column>
                <el-table-column label="设计时间" min-width="160">
                    <template slot-scope="scope">
                        {{scope.row.create_time | timeFilter}}
                    </template>
                </el-table-column>
                <el-table-column label="操作" min-width="130" align="center">
                    <template slot-scope="scope">
<!--                        <el-button type="text" class="icon-btn" @click="editTimeDialogShow(scope.row)" v-if="canEdit && auth('SCHOOL')">-->
<!--                            <el-tooltip placement="top" content="修改模板时间">-->
<!--                                <i class="iconfont icon-edit" />-->
<!--                            </el-tooltip>-->
<!--                        </el-button>-->
                        <el-button type="text" class="icon-btn" @click="routerChange(2, scope.row)" v-if="canEdit">
                            <el-tooltip placement="top" content="编辑">
                                <i class="iconfont icon-edit" />
                            </el-tooltip>
                        </el-button>
                        <el-button type="text" class="icon-btn" @click="handleDelete(scope.row.id)" v-if="canEdit">
                            <el-tooltip placement="top" content="删除">
                                <i class="iconfont icon-delete" />
                            </el-tooltip>
                        </el-button>
                        <el-button type="text" class="icon-btn" @click="routerChange(3, scope.row)">
                            <el-tooltip placement="top" content="查看">
                                <i class="iconfont icon-check" />
                            </el-tooltip>
                        </el-button>
                    </template>
                </el-table-column>
                <div class="" slot="empty">
                    <no-data></no-data>
                </div>
            </el-table>
        </div>
        <div class="page-container">
            <div></div>
            <!-- <el-button round icon="operation-btn el-icon-printer" @click="handlePrinter">打印</el-button> -->
            <el-pagination layout="total, sizes, prev, pager, next" background
                @size-change="pageSizeChange"
                @current-change="pageChange"
                :current-page="currentPage"
                :page-sizes="[total, 10, 15, 20, 25]"
                :total="total" :page-size="pageSize">
            </el-pagination>
        </div>

        <el-dialog title="修改月评模板时间范围" class="edit-time-dialog" @closed="editTimeDialogClose"
            :visible.sync="editTimeDialog" :close-on-click-modal="true" width="500px">
            <div class="edit-time-dialog-form">
                <div class="form-item">
                    <span class="key">起止时间：</span>
                    <div class="date-picker-group value">
                        <el-date-picker v-model="editForm.beginTime" type="date"
                            value-format="timestamp" placeholder="选择开始日期" @blur='checkTime("beginTime")'>
                        </el-date-picker>
                        <div class="split-line">-</div>
                        <el-date-picker v-model="editForm.endTime" type="date"
                            value-format="timestamp" placeholder="选择结束日期" @blur='checkTime("endTime")'>
                        </el-date-picker>
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="handleEditTime">保存</el-button>
                <el-button type="info" plain @click="editTimeDialogClose">关闭</el-button>
            </span>
        </el-dialog>

        <el-dialog title="复制月评模板" class="edit-time-dialog" @closed="closeSelectCopy"
                  :visible.sync="selectCopyDialog" :close-on-click-modal="false" width="550px">
            <div class="form">
                <div class="form-item">
                    <div class="form-item-name">排课计划：</div>
                    <div class="form-item-content">
                        <el-select class="input" v-model="selectCopyForm.planId" placeholder="请选择排课计划" @change="getPlanListAll">
                            <el-option v-for="item in selectPlanList" :key="item.id" :label="item.name"
                                       :value="item.id"></el-option>
                        </el-select>
                    </div>
                </div>
                <div class="form-item">
                    <div class="form-item-name">月评模板：</div>
                    <div class="form-item-content">
                        <el-select class="input" v-model="selectCopyForm.templateId" placeholder="请选择排课计划">
                            <el-option v-for="item in selectEvaluationList" :key="item.id" :label="item.topic.name"
                                       :value="item.id"></el-option>
                        </el-select>
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="saveSelectCopy">复制</el-button>
                <el-button type="info" plain @click="closeSelectCopy">关闭</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import Core from '@/core';

export default {
    components: {
        NoData: () => import('@/components/Empty.vue')
    },
    props: {
        planType: {
            type: [Number,String],
            // 1-资源方  2-学校方
        }
    },
    data() {
        return {
            userType: Core.Data.getUserType(),
            USER_TYPE: Core.Const.USER_TYPE,

            planId: 0,
            total: 0,
            currentPage: 1,
            pageSize: 10,
            editTimeDialog : false,

            canEdit: 1,
            evaluationList:[],
            multipleSelection:[],
            searchForm: {
                month: '',
            },
            editForm: {
                id: '',
                beginTime: '',
                endTime: '',
            },
            monthList: Core.Const.MONTH_LIST,
            providerName: Core.Data.getProvider(),
            selectCopyDialog: false,
            selectPlanList: [],
            selectEvaluationList: [],
            selectCopyForm: {
                planId: '',
                templateId: '',
            }
        };
    },
    computed:{
        designer() {
            let designer = this.providerName
            if (this.providerName && this.canEdit) {
                designer = ''
            }
            return designer
        },
    },
    created() {
        this.planId = this.$route.query.plan_id;
        this.canEdit = parseInt(this.$route.query.can_edit);
        this.getEvaluation();
    },
    mounted() {
    },
    methods: {
        auth: Core.Utils.auth,
        async getSelectPlanListAll() {
            let page = 1
            let selectPlanList = []
            while (true) {
                let res = await Core.Api.Plan.list(
                    page++,
                    20,
                    '',
                    '',
                    1,
                    this.schoolId || 0
                )
                if (res.list.length === 0 || page > 100) {
                    console.log('selectPlanList', selectPlanList);
                    break;
                }
                selectPlanList.push(...res.list)
                this.selectPlanList = selectPlanList;
            }

        },
        async getPlanListAll() {
            let page = 1
            let selectEvaluationList = []
            this.selectCopyForm.templateId = ''
            while (true) {
                let res = await Core.Api.Evaluation.paging(
                    this.selectCopyForm.planId,
                    0,
                    page++,
                    20,
                )
                if (res.list.length === 0 || page > 100) {
                    console.log('selectEvaluationList', selectEvaluationList);
                    break;
                }
                selectEvaluationList.push(...res.list)
                this.selectEvaluationList = selectEvaluationList;
            }

        },
        saveSelectCopy() {
            this.closeSelectCopy()
            Core.Api.Evaluation.copy(
                this.planId,
                this.selectCopyForm.templateId
            ).then(res => {
                this.$message.success("复制月评模板成功")
                this.currentPage = 1
                this.getEvaluation();
            })
        },
        pageChange(page) {
            this.currentPage = page;
            this.getEvaluation();
        },
        pageSizeChange(size) {
            this.pageSize = size;
            this.getEvaluation();
        },
        getEvaluation() {
            let month = /[0-9]+/.exec(this.searchForm.month)

            // console.log("getEvaluation -> month", month)
            if (this.searchForm.month === '') {
                month = 0
            } else if (month === null) {
                this.$message.warning('月次信息不正确，请输入正整数')

            } else {
                month = parseInt(month[0])
            }
            // console.log("getEvaluation -> month", month)

            Core.Api.Evaluation.paging(
                this.planId,
                month || 0,
                this.currentPage,
                this.pageSize,
            ).then((res) => {
                // console.log("getEvaluation -> res", res)
                this.total = res.count;
                this.evaluationList = res.list;
                this.evaluationList.forEach((form) =>{
                    form.config = JSON.parse(form.config)[0]
                })
                // console.log("getEvaluation -> this.evaluationList", this.evaluationList)
            });
        },
        handleDelete(id) {
            this.$confirm('确定执行删除操作?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                Core.Api.Evaluation.delete(id).then((res)=>{
                    this.$message.success("删除成功")
                    this.getEvaluation()
                })
            });
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        routerChange(key, q = '') {
            switch (key) {
                case 1: // 新增
                    this.$router.push({
                        path: '/plan/evaluation-edit',
                        query: { new_plan:1, plan_id: this.planId}
                    })
                    break;
                case 2: // 编辑
                    this.$router.push({
                        path: '/plan/evaluation-edit',
                        query: { id: q.id , plan_id: this.planId}
                    });
                    break;
                case 3: // 查看
                    this.$router.push({
                        path: '/plan/evaluation-view',
                        query: { id: q.id , plan_id: this.planId, can_edit: this.canEdit, view_mode:'on'}
                    });
                    break;
                case 4: // 复制
                    if (this.selectPlanList.length === 0) {
                        this.getSelectPlanListAll()
                    }
                    this.selectCopyDialog = true
                    break;
            }
        },
        handlePrinter() {
            this.$message.warning('功能暂未实装')
        },
        checkTime(type){  // 检查时间合理性
            if (this.editForm.endTime && this.editForm.beginTime) {
                if (this.editForm.endTime < this.editForm.beginTime) {
                    this.$message.warning('请选择合理的时间范围');
                    // this.editForm[type] = '';
                }
            }
        },
        editTimeDialogShow(item) {  // 显示修改时间弹窗
            this.editForm = JSON.parse(JSON.stringify(item))
            this.editForm.beginTime = item.begin_time * 1000
            this.editForm.endTime = item.end_time * 1000

            this.editTimeDialog = true
        },
        editTimeDialogClose() { // 取消修改时间
            this.editTimeDialog = false
            this.editForm = {
                id: '',
                beginTime: '',
                endTime: '',
            }
        },
        closeSelectCopy() {
            this.selectCopyDialog = false
        },
        handleEditTime() {      // 确定修改时间
            if (!this.editForm.beginTime) {
                return this.$message.warning('起始日期不能为空')
            } else if (!this.editForm.endTime) {
                return this.$message.warning('截止日期不能为空')
            } else if (this.editForm.endTime < this.editForm.beginTime) {
                return this.$message.warning('请选择合理的时间范围');
            }
            let month = (new Date((this.editForm.beginTime/1 + this.editForm.endTime/1)/2)).getMonth()+1;

            console.log("handleEditTime -> month", month)

            let editForm = this.editForm
            console.log("handleEditTime -> editForm", editForm)

            Core.Api.SchoolEvaluation.save(
                editForm.id,
                editForm.beginTime / 1000,
                editForm.endTime / 1000,
                month,
                editForm.plan_id,
                editForm.topic_id,
                JSON.stringify(editForm.config)
            ).then(res => {
                this.$message.success('修改成功！')
                this.getEvaluation();
            }).finally(e => {
                this.editTimeDialog = false
            })
        },

    },
};
</script>
<style lang="scss" scoped>
.container {
    @import '@/common/styles/table.scss';
    .search-container{
        @include flex(row, flex-start, center);
    }
    .page-container {
        justify-content: space-between;
        .operation-btn {
            i {
                color: rgb(165, 173, 180);
            }
        }
    }
    .edit-time-dialog {
        .edit-time-dialog-form {
            .form-item {
                @include flex(row, space-between, center);
                .key {
                    width: 80px;
                }
                .value {
                    width: calc(100% - 80px);
                }
            }
        }
        .dialog-footer {
            display: flex;
            justify-content: center;
        }
    }
}
.EvaluationList {
    .form {
        width: 100%;
        .form-item {
            margin-bottom: 20px;
            @include flex(row, flex-start, center);
            &:last-child {
                margin-bottom: 0px;
            }
            .form-item-name {
                font-weight: 500;
                width: 80px;
            }
            .form-item-content {
                flex: 1;
                .el-select {
                    width: 90%;
                }
            }
        }
    }
}
</style>
